@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.footer-section {
    text-align: center;
    padding: 20px;
    color: #838391;
    background-color: white;

    @include sm {
        font-size: 14px;
    }
}