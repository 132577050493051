@import '../../assets/scss/variables';


.hero_section {
    width: 100%;
    margin-top: 78px;

    >video {
        width: 100%;
        object-fit: cover;
    }
}