.media-container {
  padding: 10rem 0;
  width: 100%;
  display: flex;
  justify-content: center;

  .tabs {
    width: 70%;

    ul {
      border-bottom: 1px solid #D4AE62;
      margin: 0 0 25px;

      .react-tabs__tab--selected {
        border-color: #D4AE62;
      }
    }

    .picture {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-evenly;
    }

    .media-item {
      width: 240px;
      height: 240px;

      video {
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}