@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.about {
  background-image: url('../../assets/images/sections/3a.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 28px 20px;
  min-height: 500px;
  color: white;
  border-bottom: 1px solid $gold;

  .underline {
    background-color: $gold;
    width: 60px;
    height: 6px;
    display: block;
    margin-top: 4px;
  }

  &-spea {
    display: flex;
    justify-content: flex-end;
    text-align: left;
    width: 100%;

    &-details {
      padding: 16px;
      position: relative;

      .background-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #d4ae623d;
        opacity: .4;
        filter: blur(10px);

      }

      >h1 {
        text-align: left;
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        position: relative;
        z-index: 1;
      }

      >p {
        font-size: 14px;
        letter-spacing: 1.3px;
        font-weight: 400;
        position: relative;
        z-index: 1;
        text-align: justify;
      }

      @include sm {
        padding: unset;
        max-width: unset;
      }
    }
  }

  .goals {
    margin-top: 32px;

    >h3 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    >ul {
      >li {
        list-style: none;
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }

        >span {
          font-size: 14px;
          letter-spacing: 1.3px;
          font-weight: 400;
          margin-left: 8px;
        }

        svg {
          height: 46px;
          width: 46.83px;
        }
      }
    }
  }

  @include lg {
    background-image: url('../../assets/images/sections/20.jpeg');
  }

  @include sm {
    min-height: 630px;
  }
}