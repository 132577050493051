.date-container {
  padding: 10rem 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .media {
    min-width: 250px;
    width: 450px;
    height: 17rem;
    background-color: rgb(255, 255, 255);
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease-out;

    &:hover {
      background-color: rgb(250, 250, 250);

      >a {
        font-size: 56px;
      }
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 40px;
    color: #D4AE62;
    opacity: 0.8;
    font-weight: 500;
    transition: all .3s ease-out;
  }
}