@import '../assets/scss/variables';
@import '../assets/scss/mixins';

.excellence {
    padding: 28px 20px;
    border-bottom: 1px solid rgb(209, 182, 113);
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    grid-gap: 24px;


    &-photo {
        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include md {
            display: none;
        }
    }

    .mobile-header {
        display: none;
        font-size: 24px;
        font-weight: 600;
        color: #838391;
        text-align: center;
        margin: 0 auto;

        .underline {
            background-color: $gold;
            width: 100px;
            height: 6px;
            display: block;
            margin-top: 4px;
        }

        @include md {
            display: none;
        }
    }

    &-awards {
        text-align: justify;

        &-header {
            >h1 {
                color: #838391;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 4px;
                padding-left: 4px;
                border-left: 4px solid $gold;
            }

            >p {
                color: #474769;
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 4px;
            }
        }

        &-item {
            >h3 {
                color: #838391;
                margin-bottom: 4px;
                font-weight: 600;
                font-size: 16px;
            }

            >p {
                font-size: 14px;
                color: #474769;
                margin-left: 24px;
            }

            ul {
                li {
                    color: #474769;
                    margin-left: 24px;
                    list-style: none;
                    padding-left: 7px;
                    font-size: 14px;

                    &::marker {
                        content: url('../assets/svgs/marker-arrow.svg');
                    }
                }
            }

            .download-btn {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                padding: 12px 36px 12px 24px;
                font-size: 14px;
                font-weight: 600;
                clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
                border: none;
                cursor: pointer;
                transition: all .3s ease-in;
                background: #838391;
                color: white;
                display: block;
                max-width: 180px;
                text-decoration: none;

                &:hover {
                    background: #71717c;
                }
            }
        }
    }

    @include md {
        grid-template-columns: unset;
    }
}