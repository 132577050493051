@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.sponsors {
    padding: 28px 20px;
    border-bottom: 1px solid rgb(209, 182, 113);

    .sponsors-title {
        text-align: center;
        color: #838391;
        max-width: 412px;
        margin: 0 auto;

        .underline {
            background-color: $gold;
            width: 100px;
            height: 6px;
            display: block;
            margin-top: 4px;
        }

        @include sm {
            font-size: 24px;
            max-width: 312px;
        }
    }

    .logos {
        img {
            height: 60px;
            margin: 10px 20px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .logos>img {
        height: 50px !important;
    }
}

@media only screen and (max-width: 320px) {
    .logos>img {
        height: 40px !important;
    }
}