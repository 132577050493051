@import '../../assets/scss/variables';
@import '../../assets/scss//mixins';

.header {
  width: 100%;
  position: fixed;
  top: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 8px 20px 0;
  background: white;
  z-index: 5;

  .website-logo {
    max-width: 166px;
    height: 70px;

    >img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }

    @include lg {
      width: unset;
    }
  }

  .navbar {
    display: flex;
    justify-content: space-around;

    a {
      text-decoration: none;

      &.active {
        border-bottom: 2px solid $gold;
      }
    }

    .header-navigation {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 42px;

        >li {
          display: inline-block;
          margin: 0px 20px;
          padding: 0px 10px;
          padding-bottom: 4px;
          border-bottom: 2px solid transparent;

          >a {
            font-size: 18px;
            font-weight: 600;
            color: rgb(102, 102, 102);
            text-decoration: none;

            @include xl {
              font-size: 16px;
            }

            @include lg {
              font-size: 14px;
            }

            @include md {
              font-size: 12px;
            }
          }

          &.active {
            border-bottom: 2px solid $gold;
          }

          @include lg {
            margin: 0;
          }
        }

        @include lg {
          margin-top: 4px;
        }

        @include sm {
          flex-direction: column;
        }


      }

      @include md {
        &.collapsed {
          max-height: 0px !important;
          overflow: hidden;
        }

        &.expanded {
          max-height: 1000px !important;
          transition: max-height 0.25s ease-in;
        }
      }
    }

    .media {
      display: flex;

      >span {
        margin-top: 42px;
        font-size: 17px;
        font-weight: 600;
        color: rgb(102, 102, 102);
        text-decoration: none;
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;

        @include xl {
          font-size: 16px;
        }

        @include lg {
          font-size: 14px;
        }

        @include md {
          font-size: 12px;
          margin-top: 7px !important;

        }

        @include lg {
          margin-top: 4px;
        }

        @include sm {
          justify-content: center;
          margin-top: 0 !important;
        }

      }

      @include sm {
        justify-content: center;
      }
    }

    @include lg {
      width: 100%;
      justify-content: space-evenly;
      padding-left: 50px;
    }

    @include sm {
      flex-direction: column;
    }
  }




  >.burger {
    display: none;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  @include lg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-justify-items: flex-start;
  }

  @include md {
    >.burger {
      display: block;
    }
  }

  @include sm {
    justify-content: space-between;
  }
}