h1, h2 {
    color: #333;
    margin: 25px 0px;
}
p {
    margin-bottom: 20px;
}
.container{
    margin: 85px 15px 0px 15px;
}
li{
    display: list-item  ;
}